// Configuration API
let host = location.hostname;
const LOCAL_DOMAINS = ["localhost","127.0.0.1","192.168"];
const EPLAN_DOMAINS  = ["persediaan.e-planningbmd.id"];
if (LOCAL_DOMAINS.includes(host)) { 
    // host = location.protocol+"//"+location.host+"/";
    host = "http://localhost:8080/";
} else if (EPLAN_DOMAINS.includes(host)) { 
    var domain = location.hostname;
    var subdomain = domain.split('.')[0];
    var domain_rest = domain.replace(subdomain, "");
    host = location.protocol+"//api-"+subdomain+""+domain_rest+"/";
}else{
    host = "https://persediaanbmd.bandungkab.go.id/api/";
}
export default {
    host : host
};
