import Vue from 'vue';
import VueRouter from 'vue-router';
// import Login from '@/views/admin/authentication/maintenance.vue';
import Login from '@/views/admin/authentication/Signin.vue';
// import Beranda from '@/views/admin/authentication/maintenance.vue';
import Beranda from '@/views/siswa/Beranda.vue';
import VueMeta from 'vue-meta';
import Axios from 'axios';
import configuration from './configuration';
import VueBodyClass from 'vue-body-class';

import Home from '@/views/admin/dashboard/Dashboard.vue';
// import Home from '@/views/admin/authentication/maintenance.vue';
import SideBar from '@/layouts/SideBar.vue';
// import SideBar from '@/views/admin/authentication/maintenance.vue';
import MainWebsite from '@/layouts/MainWebsite.vue';
import PageNotFound from '@/layouts/PageNotFound.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [{
        path: '/admin/dashboard',
        name: 'admin.dashboard',
        component: Home,
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/general/profile',
        name: 'profile',
        component: () => import('@/views/component/Profile.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','uptd']
        }
    },
    {
        path: '/admin/master/pengguna',
        name: 'master.pengguna',
        component: () => import('@/views/admin/master/MasterPengguna.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/pengguna/detail/:id',
        name: 'master.pengguna_detail',
        component: () => import('@/views/admin/master/MasterPenggunaTurunan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/persediaan',
        name: 'master.persediaan',
        component: () => import('@/views/admin/master/MasterPersediaan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/bidang',
        name: 'master.bidang',
        component: () => import('@/views/admin/master/MasterBidang.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang']
        }
    },
    {
        path: '/admin/master/pengurus',
        name: 'master.pengurus',
        component: () => import('@/views/admin/master/MasterPengurus.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang']
        }
    },
    {
        path: '/admin/master/riwayat/:id',
        name: 'master.history',
        component: () => import('@/views/admin/master/MasterBidangHistory.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang']
        }
    },
    {
        path: '/admin/pengurus/riwayat',
        name: 'profil.history_pengurus',
        component: () => import('@/views/admin/master/MasterPengurusHistory.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang']
        }
    },
    {
        path: '/admin/sekretariat/riwayat',
        name: 'profil.history_sekretariat',
        component: () => import('@/views/admin/master/MasterSekretariatHistory.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang']
        }
    },
    {
        path: '/admin/kadis/riwayat',
        name: 'profil.history_kadis',
        component: () => import('@/views/admin/master/MasterKadisHistory.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang']
        }
    },
    {
        path: '/admin/master/usulan',
        name: 'master.usulan',
        component: () => import('@/views/admin/master/MasterUsulan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','uptd']
        }
    },
    {
        path: '/admin/master/satuan',
        name: 'master.satuan',
        component: () => import('@/views/admin/master/MasterSatuan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang']
        }
    },

    {
        path: '/transaksi/pengajuan',
        name: 'transaksi.pengajuan',
        component: () => import('@/views/component/pengajuan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['uptd','bidang', 'auditor']
        }
    },
    {
        path: '/transaksi/pengajuan/form/:id',
        name: 'transaksi_masuk.form_pengajuan',
        component: () => import('@/views/component/pengajuan_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/transaksi/validasi',
        name: 'transaksi.validasi',
        component: () => import('@/views/component/validasi.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['dinas','bidang', 'auditor']
        }
    },
    {
        path: '/transaksi/dropping',
        name: 'transaksi.dropping',
        component: () => import('@/views/component/dropping.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['uptd','bidang', 'auditor']
        }
    },
    {
        path: '/transaksi/dropping/form/:id',
        name: 'transaksi.form_dropping',
        component: () => import('@/views/component/dropping_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['bidang','uptd','auditor']
        }
    },
    {
        path: '/transaksi/penggunaan',
        name: 'transaksi.penggunaan',
        component: () => import('@/views/component/penggunaan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['uptd','bidang','auditor']
        }
    },
    {
        path: '/transaksi/penggunaan/form/:id',
        name: 'transaksi.form_penggunaan',
        component: () => import('@/views/component/penggunaan_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['bidang','uptd','auditor']
        }
    },
    {
        path: '/transaksi/pembelian/form/:id',
        name: 'transaksi_masuk.form',
        component: () => import('@/views/component/pembelian_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/pembelian',
        name: 'transaksi.pembelian',
        component: () => import('@/views/component/pembeliaan.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/pengembalian/form/:id',
        name: 'transaksi_masuk.form_pengembalian',
        component: () => import('@/views/component/pengembalian_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/pengembalian',
        name: 'transaksi.pengembalian',
        component: () => import('@/views/component/pengembalian.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/saldo',
        name: 'transaksi.saldo',
        component: () => import('@/views/component/saldo.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/saldo/form/:id',
        name: 'transaksi_masuk.form_saldo',
        component: () => import('@/views/component/saldo_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/hibah_masuk',
        name: 'transaksi.hibah_masuk',
        component: () => import('@/views/component/hibah_masuk.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/hibah_masuk/form/:id',
        name: 'transaksi_masuk.form_hibah',
        component: () => import('@/views/component/hibah_masuk_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/dropping_masuk',
        name: 'transaksi.dropping_masuk',
        component: () => import('@/views/component/dropping_masuk.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/dropping_masuk/form/:id',
        name: 'transaksi_masuk.form_dropping',
        component: () => import('@/views/component/dropping_masuk_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/pemakaian',
        name: 'transaksi.pemakaian',
        component: () => import('@/views/component/pemakaian.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/transaksi/pemakaian/form/:id',
        name: 'transaksi_keluar.form',
        component: () => import('@/views/component/pemakaian_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/transaksi/hibah_keluar',
        name: 'transaksi.hibah_keluar',
        component: () => import('@/views/component/hibah_keluar.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/hibah_keluar/form/:id',
        name: 'transaksi_keluar.form_hibah',
        component: () => import('@/views/component/hibah_keluar_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/dropping_keluar',
        name: 'transaksi.dropping_keluar',
        component: () => import('@/views/component/dropping_keluar.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/dropping_keluar/form/:id',
        name: 'transaksi_keluar.form_dropping',
        component: () => import('@/views/component/dropping_keluar_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/rusak',
        name: 'transaksi.rusak',
        component: () => import('@/views/component/rusak_keluar.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/rusak/form/:id',
        name: 'transaksi_keluar.form_rusak',
        component: () => import('@/views/component/rusak_keluar_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/usang',
        name: 'transaksi.usang',
        component: () => import('@/views/component/usang_keluar.vue'),
        meta: {
            // transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/transaksi/usang/form/:id',
        name: 'transaksi_keluar.form_usang',
        component: () => import('@/views/component/usang_keluar_form.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','auditor']
        }
    },
    {
        path: '/laporan/stock_opname',
        name: 'laporan.stock',
        component: () => import('@/views/component/laporan_stock.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/laporan/persediaan',
        name: 'laporan.persediaan',
        component: () => import('@/views/component/laporan_persediaan.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/laporan/rusak_usang',
        name: 'laporan.rusak_usang',
        component: () => import('@/views/component/laporan/rusak_usang.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/laporan/mutasi',
        name: 'laporan.mutasi',
        component: () => import('@/views/component/laporan_mutasi.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/laporan/buku_penerimaan',
        name: 'laporan.buku_penerimaan',
        component: () => import('@/views/component/laporan/buku_penerimaan.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/laporan/buku_pengeluaran',
        name: 'laporan.buku_pengeluaran',
        component: () => import('@/views/component/laporan/buku_pengeluaran.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd', 'auditor']
        }
    },
    {
        path: '/laporan/buku_penyaluran',
        name: 'laporan.buku_penyaluran',
        component: () => import('@/views/component/laporan/buku_penyaluran.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/laporan/kartu_barang',
        name: 'laporan.kartu_barang',
        component: () => import('@/views/component/laporan/kartu_barang.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/laporan/perubahan_fisik',
        name: 'laporan.perubahan_fisik',
        component: () => import('@/views/component/laporan/perubahan_fisik.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/admin/riwayat',
        name: 'admin.riwayat',
        component: () => import('@/views/component/history.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd']
        }
    },
    {
        path: '/laporan',
        name: 'laporan',
        component: () => import('@/views/component/laporan/index.vue'),
        meta: {
        //   transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },
    {
        path: '/laporan/empty',
        name: 'laporan.empty',
        component: () => import('@/views/component/laporan/empty.vue'),
        meta: {
          transition: 'fade',
          layout: SideBar,
          roles : ['administrator','dinas','bidang','uptd','auditor']
        }
    },

    // ===========================================
    {
        path: '/admin/master/akses',
        name: 'master.akses',
        component: () => import('@/views/admin/master/MasterAkses.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator','dinas']
        }
    },

    /* -------------------------------------------------------------- */
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/home',
        name: 'home',
        component: Beranda,
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '/sign-up',
        name: 'signup',
        component: () => import('@/views/siswa/SignUp.vue'),
    },
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/admin/authentication/Signout.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: PageNotFound,
    },      
];

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["login", "signup", "home"];
    const authRequired = !publicPages.includes(to.name);
    const email = localStorage.getItem('email');

    // if user login without twofactor authentication
    if (!email) {
        const token = localStorage.getItem('auth_token');
        if (authRequired && token) {
            Axios.get(configuration.host + 'auth/validate_user', {
                headers: { 'Authorization': token }
            }).then(response => {
                if (!response.data.status) {
                    localStorage.removeItem('id');
                    localStorage.removeItem('auth_token');
                    next('/');
                } else {
                    let roles = response.data.role;
                    
                    if(to.meta.roles.includes(roles)) {
                        next();
                    } else {
                        alert("Akses tidak diperbolehkan");
                        next({ name: 'home' });
                    }
                }
            }).catch(function(error) {
                console.debug(error);
            });
        } else if (authRequired && !token) {
            next({ name: 'home' });
        } else {
            next();
        }
    }

    vueBodyClass.guard(to, next);
});

export default router;