<template>
    <div>
        <div class="d-block" v-bind:style="{ 'min-height' : '75vh' }">
            <div class="container-fluid header-fixed">
                <div class="row text-center">
                    <div class="col-md-12">
                        <div class="d-block pt-lg-5 pt-md-5 pt-3">
                            <img class="mb-5" :src="'https://sshsbu.e-planningbmd.id/assets/images/bandung.png'" :style="'width:10%'"/>
                            <h1>Oops! Halaman tidak ditemukan.</h1>
                            <button v-on:click="kembali" type="button" class="btn btn-lg btn-primary mb-3">
                                Kembali ke halaman depan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "PageNotFound",
    methods : {
        kembali() {
            this.$router.push('/admin/dashboard');
        }
    }
};
</script>
