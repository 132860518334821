<template>
    <div>
        <slot />
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        mounted() {
            this.$store.dispatch('loadUserData');
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['userdata', 'school_info']),
    }
</script>