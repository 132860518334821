<template>
    <div>
        <div v-if="userdata.phone == 0" class="row justify-content-center">
            <div class="col-md-6 col-lg-6 pt-4">
                <div class="card mg-b-20">
                    <div class="card-header">
                        <h4 class="card-header-title">
                            Form Upload Dokumen
                        </h4>
                    </div>
                    <div class="card-body collapse show" id="collapse3">
                        <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-12 mg-b-30">
                            <hr class="d-md-none">
                            <p>Surat Pernyataan<span class="text-danger">*</span></p>
                            <input ref="fileInput"  required="" @change="uploadFile" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" placeholder="">
                            </div>
                            <div class="col-md-12 mg-b-30">
                            <hr class="d-md-none">
                            <p>BA Stock Opname</p>
                            <input ref="fileInput1" @change="uploadFile1" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" placeholder="">
                            </div>
                            <div class="col-md-12 mg-b-30">
                            <button class="col-md-12 btn btn-oblong btn-primary">Simpan Data</button>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6">
                <div class="alert alert-danger alert-bordered pd-y-15 mt-4" role="alert">
                    <div class="d-sm-flex align-items-center justify-content-start">
                        <div class="mg-t-20 mg-sm-t-0">
                            <h5 class="mg-b-2 tx-dark">INFORMASI !!! <button class="btn btn-sm btn-warning bounce-button" type="button" style="cursor:none">⚠️ HARAP DIPERHATIAKAN DAN DIIKUTI</button></h5>
                            <p class="mg-b-0 pt-2 tx-dark">- Semua menu tidak dapat diakses sebelum surat pernyataan di upload</p>
                            <p class="mg-b-0 pt-2 tx-dark">- Silahkan unduh template surat penyataan <a target="_blank" href="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/7eaf6ae85951f58d05ee6d96/SURAT%20PERNYATAAN%20MUTLAK%20%28PERSEDIAAN%29.docx" class="text-info"><b> Pada link ini</b></a> atau yang sudah di share di grup</p>
                            <p class="mg-b-0 pt-2 tx-dark">- Silahkan upload surat pernyataan pada form "Upload Surat Pernyataan"</p>
                            <p class="mg-b-0 pt-2 tx-dark">- Jika surat pernyataan berhasil di upload, maka semua menu dapat diakses kembali</p>
                            <p class="mg-b-0 pt-2 tx-dark">- Silahkan upload juga BA Stock Opname pada form "Upload BA Stock Opname", yang sudah ditandatangani dan dicap</p>
                            <p class="mg-b-0 pt-2 tx-dark">- Bagi yang sudah melakukan upload Surat Pernyataan dan BA Stock Opname maka sudah bisa melakukan transaksi untuk tahun 2024 dengan catatan sudah lolos validasi BMD</p>
                            <p class="mg-b-0 pt-2 tx-dark">- Saldo awal 2024 akan diambil dari Stock Opname 2023</p>
                        </div>
                    </div>
                </div>

                <div class="alert alert-warning alert-bordered pd-y-15" role="alert">
                    <div class="d-sm-flex align-items-center justify-content-start">
                        <div class="mg-t-20 mg-sm-t-0">
                            <p class="mg-b-0 tx-gray">Silahkan <a target="_blank" href="https://wa.me/6289637421032?text=SKPD+:+%0A%0A+Kendala+:" class="text-success"><b><i class="fa fa-whatsapp mg-r-5"></i>Hubungi Admin</b></a> jika perlu bantuan</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="userdata.phone != 0" class="row justify-content-center">
            <div class="col-12 col-lg-6 col-xl-6 ">
                
                <div class="d-block d-flex mt-4" style="border:1px black solid">
                    <img v-if="checkDomain != 'persediaan.e-planningbmd.id'" width="100%" height="100%" src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/b6f97fd4839f937f7a98c700/PENGUMUMAN%20%281%29.jpg" >
                    <img v-if="checkDomain == 'persediaan.e-planningbmd.id'" width="100%" height="100%" src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/b6f97fd4839f937f7a98c700/PENGUMUMAN%20%281%29.jpg" >
                    <!-- <video width="100%" height="305" controls>
                        <source src="https://scola-paudpermatahati.scolacdn.com/timeline/market_day_230901_140746.mp4" type="video/mp4">
                    </video> -->
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-6">
                <div class="d-block">
                    <div class="d-flex mt-4 p-3 alert alert-success text-dark rounded align-items-center">
                        <div class="mr-3"><img style="width:70px" src="https://kdo.e-planningbmd.id/assets/images/monitor.svg" width="30"></div>
                        <div>
                            <h4 style="color:black !important" class="text-bold text-dark mb-2">Sistem Informasi Persediaan</h4>
                            <p class="mb-2">layanan sistem informasi Persediaan Badan Keuangan dan Aset Daerah</p>
                            <p class="text-sm m-0">Periode : 
                                <!-- HIDE SEMENTARA -->
                                <a v-if="checkDomain == 'persediaan.e-planningbmd.id'" class="text-primary">2024 </a>
                                <!-- HIDE SEMENTARA -->
                                <a v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="text-primary">2024 </a>
                            </p>
                        </div>
                    </div>
                </div>

                <div v-if="userdata.role != 'auditor' && userdata.role != 'administrator' && checkDomain != 'persediaan.e-planningbmd.id'" class="alert alert-warning alert-bordered mt-4 pd-y-15" role="alert">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <!-- <span aria-hidden="true"><i class="ico icon-close"></i></span> -->
                    </button>
                    <div class="d-sm-flex align-items-center justify-content-start">
                        <i class="icon ion-alert-circled alert-icon tx-52 tx-warning mg-r-20"></i>
                        <div class="mg-t-20 mg-sm-t-0">
                            <h5 class="mg-b-2 tx-warning">PERHATIAN !!!</h5>
                            <p class="mg-b-0 tx-gray">Kendala seputar kebijakan ? <a target="_blank" href="https://wa.me/6289637421032?text=SKPD+:+%0A%0A+Kendala+:" class="text-success"><b><i class="fa fa-whatsapp mg-r-5"></i>Hubungi Admin</b></a></p>
                            <p class="mg-b-0 tx-gray">Kendala seputar website ? <a target="_blank" href="https://wa.me/6283822355705?text=SKPD+:+%0A%0A+Kendala+:" class="text-success"><b><i class="fa fa-whatsapp mg-r-5"></i>Hubungi Tim Teknis</b></a></p>
                        </div>
                    </div>
                </div>

                <div v-if="userdata.role != 'auditor' && userdata.role != 'administrator' && checkDomain == 'persediaan.e-planningbmd.id'" class="alert alert-danger d-none" role="alert">
                   Transaksi tahun 2024 hanya bisa di input jika BA Stockopname sudah di validasi oleh Admin. Jika sudah divalidasi maka akan ada tombol untuk beralih ke tahun 2024 
                   <div v-if="profil[0].phone < 9" class="card mg-b-20 mt-2">
                    <div class="card-body collapse show" id="collapse3">
                        <form @submit.prevent="submitFormBa" enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-9">
                                <label class="text-dark mb-1">Surat Pernyataan &emsp; <a v-if="profil[0].sp" target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/api/dokumen/' + profil[0].sp" class="text-primary"><i class="fa fa-external-link mr-1"></i>Lihat File Sebelumnya</a></label>
                                <input ref="fileInput"  @change="uploadFile" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" placeholder="">
                                <hr>
                                <label class="text-dark mb-1">BA Stock Opname &emsp; <a v-if="profil[0].ba" target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/api/dokumen/' + profil[0].ba" class="text-primary"><i class="fa fa-external-link mr-1"></i>Lihat File Sebelumnya</a></label>
                                <input ref="fileInput1" @change="uploadFile1" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" placeholder="">
                            </div>
                            <div class="col-md-3">
                                <label class="text-dark mb-1">&nbsp;</label><br>
                                <label class="text-dark mb-1">&nbsp;</label><br>
                                <button style="height:50%;vertical-align: center;" class="col-md-12 btn btn-primary">Simpan/<br>Perbaharui</button>
                            </div>
                        </div>
                        </form>
                    </div>
                   </div>
                </div>

                <!-- <div v-if="userdata.role != 'auditor' && userdata.role != 'administrator' && checkDomain != 'persediaan.e-planningbmd.id'" class="alert alert-danger" role="alert">
                   Pastikan terlebih dahulu saldo awal 2024 sudah sesuai dengan stock opname 2023, sebelum melakukan transaksi !!!
                </div> -->
                <!-- <div v-if="userdata.role != 'auditor'" class="alert alert-danger" role="alert">
                   Untuk melengkapi Profil silahkan !!! Klik 
                   <b>
                       <router-link to="/general/profile">link</router-link>
                    </b> 
                   berikut 
                </div> -->
                <!-- <div v-if="userdata.role != 'auditor'" class="alert alert-info bounce-button" role="alert">
                   📃 Unduh
                   <b style="color:blue">
                       <router-link style="color:blue" to="/general/profile"></router-link>
                        <a target="_blank" :href="'https://persediaanbmd.bandungkab.go.id/print/Home/surat_pertanggungjawaban/' + tokenFix + '/' + userdata.id" class="text-primary">Surat Pernyataan Tanggung Jawab Mutlak</a>
                                    </b> 
                   berikut 
                </div> -->

                <!-- FILTER AUDITOR -->
                <div v-if="userdata.role == 'auditor' || userdata.role == 'administrator'" class="d-block">
                    <hr>
                    <div  class="col-12 card p-2">
                    Filter SKPD:
                    <v-select 
                            :options="listPengguna"
                            :reduce="(label) => label.code"
                            label="label"
                            v-model="filter"
                            @input="handleFilter"
                            ></v-select>
                    </div>
                </div>
                
                <!-- FILTER AUDITOR -->

            </div>

            <div class="col-sm-12 mb-3 mt-3">
                <div class="row">
                    <!-- <div v-if="userdata.role == 'auditor' || userdata.role == 'administrator'" class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Data Pengguna</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pengguna | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="userdata.role == 'auditor' || userdata.role == 'administrator'" class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Data Satuan</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.satuan | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Kode Persediaan</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.persediaan | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Usulan Barang</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.usulan | VMask(currencyMask) }}</span></h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-info wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-dark">
                            <div class="card-body bg-secondary">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="tx-uppercase text-white tx-10 mg-b-10">Rusak/Usang 2023</span>					  
                                        <span v-if="checkDomain == 'persediaan.e-planningbmd.id'" class="tx-uppercase text-white tx-10 mg-b-10">Rusak/Usang 2022</span>					  
                                        <h2 class="text-white tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                                            <span class="float-left">
                                            Rp. <span class="tx-white">{{ statistik.rusakUsang | VMask(currencyMask) }}</span>
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-white wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="card border-dark">
                            <div class="card-body bg-warning">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Sisa Saldo</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark">
                                            <span class="float-right">
                                            Rp. <span class="tx-dark">{{ statistik.sisaSaldo | VMask(currencyMask) }}</span>
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="border:1px solid gray">
                <!-- TRANSAKSI MASUK -->
                <div v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="row">
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Saldo Awal</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.saldo | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.saldo_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Pembelian</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pembelian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pembelian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Hibah Masuk</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.hibah_masuk | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.hibah_masuk_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Pengembalian</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pengembalian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pengembalian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TRANSAKSI KELUAR -->
                <div v-if="checkDomain != 'persediaan.e-planningbmd.id'" class="row mt-3">
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Pemakaian</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pemakaian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pemakaian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Hibah Keluar</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.hibah_keluar | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.hibah_keluar_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Rusak</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.rusak | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.rusak_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Usang</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.usang | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.usang_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- HIDE SEMENTARA -->
                <div v-if="checkDomain == 'persediaan.e-planningbmd.id'" class="row">
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Transaksi Masuk</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pembelian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pembelian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-success wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="card">
                            <div class="card-body">
                                <div class="media d-inline-flex">
                                    <div>
                                        <span class="tx-uppercase tx-10 mg-b-10">Transaksi Keluar</span>					  
                                        <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ statistik.pemakaian | VMask(currencyMask) }}</span><span class="tx-11 tx-gray-500"> Transaksi</span></h2>
                                    </div>
                                </div>
                                <div class="clearfix"> 
                                    
                                    <span class="float-right">
                                    Rp. <span class="tx-dark">{{ statistik.pemakaian_rp | VMask(currencyMask) }}</span>
                                    </span>
                                </div>
                                <div class="progress ht-3 op-5">
                                    <div class="progress-bar bg-danger wd-100p" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- HIDE SEMENTARA -->
            </div>

        </div>
    </div>
</template>
<script>
    // import vPagination from 'vue-plain-pagination'
    import { mapState } from 'vuex'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Dashboard'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                currencyMask,
                dataPengguna: [],
                listPengguna: [],
                profil: {},
                filter: '',
                checkDomain: '',
                form: {
                    surat_pernyataan: '',
                    ba_stockopname: '',
                },
                statistik: {
                    pengguna: 0,
                    persediaan: 0,
                    satuan: 0,
                    usulan: 0,
                    saldo: 0,
                    pembelian: 0,
                    hibah_masuk: 0,
                    pemakaian: 0,
                    hibah_keluar: 0,
                    saldo_rp: 0,
                    pembelian_rp: 0,
                    hibah_masuk_rp: 0,
                    pemakaian_rp: 0,
                    hibah_keluar_rp: 0,
                },
                // pagination: {         
                //     currentPage: 1,
                //     totalPages: 0,
                //     limit: 10,
                //     bootstrapPaginationClasses: {
                //         ul: 'pagination',
                //         li: 'page-item',
                //         liActive: 'active',
                //         liDisable: 'disabled',
                //         button: 'page-link'  
                //     },
                //     paginationAnchorTexts: {
                //         first: '<i class="fe fe-chevrons-left"></i>',
                //         prev: '<i class="fe fe-chevron-left"></i>',
                //         next: '<i class="fe fe-chevron-right"></i>',
                //         last: '<i class="fe fe-chevrons-right"></i>'
                //     }
                // },
                keywords: '',
            }
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.getProfil();
            this.getUserList();
            this.getStatistik();
            this.check_domain();
        },
        components: {
            'v-select' : vSelect,
        },
        computed: mapState(['userdata', 'school_info']),
        methods: {
            // getData() {
            //     this.fetching = true;
            //     this.$http.get(this.baseUrl + 'admin/beranda', {
            //         params: {
            //             page : this.pagination.currentPage,
            //             keywords: this.keywords,
            //         }
            //     })
            //         .then((response) => {
            //             this.dataPengguna  = response.data.data;
            //             this.totalPengguna = response.data.total;
            //             this.fetching = false;
            //             this.pagination.totalPages = response.data.total_page;
            //         })
            //         .catch((error) => {
            //             console.log(error.response.data);
            //             this.fetching = false;
            //         });
            // },
            check_domain() {
                // this.checkDomain = 'persediaanbmd.bandungkab.go.id';
                this.checkDomain = window.location.hostname;
            },
            getStatistik() {
                this.$http.get(this.baseUrl + 'admin/beranda/statistik', {
                    params: {
                        filter: this.filter,
                    }
                })
                    .then((response) => {
                        this.statistik = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            uploadFile() {
                const file = this.$refs.fileInput.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.surat_pernyataan = this.$refs.fileInput.files[0];
                }
            },
            uploadFile1() {
                const file = this.$refs.fileInput1.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.ba_stockopname = this.$refs.fileInput1.files[0];
                }
            },
            submitForm() {
                const formData = new FormData();
                formData.append('surat_pernyataan', this.form.surat_pernyataan);
                formData.append('ba_stockopname', this.form.ba_stockopname);

                this.$http.post(this.baseUrl + 'general/dokumen', formData)
                .then((response) => {
                    if(response.data.status){
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data berhasil disimpan, Silahkan login ulang!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$router.push('/signout');
                    }
                })
                .catch(error => {
                    console.log(error.response.data)
                });
            },
            submitFormBa() {
                const formData = new FormData();
                formData.append('surat_pernyataan', this.form.surat_pernyataan);
                formData.append('ba_stockopname', this.form.ba_stockopname);

                this.$http.post(this.baseUrl + 'general/dokumen_ba', formData)
                .then((response) => {
                    if(response.data.status){
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data berhasil disimpan!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.getProfil();
                    }
                })
                .catch(error => {
                    console.log(error.response.data)
                });
            },
            getUserList() {
                this.fetching = true;
                // if(this.userdata.role == 'auditor'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/list', {
                    params: {
                            keywords: this.keywords,
                        }
                    })
                        .then((response) => {
                            let data = response.data.data;
                            data.forEach((item) => {
                                this.listPengguna.push({
                                label: item.name,
                                code: item.id,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                // }
            },
            handleFilter: function() {
                this.getStatistik();
            },
            getProfil() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/pengguna/profil', {
                params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.profil = response.data.data;
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    }); 
            },
        },
    };
</script>