<template>
    <div class="page-container">
        <div class="page-sidebar">
            <div class="logo">
                <a class="logo-img">
                    
                    <img class="desktop-logo" style="width:40px;" src="https://sshsbu.e-planningbmd.id/assets/images/bandung.png" alt="">
                    <img class="small-logo" src="https://sshsbu.e-planningbmd.id/assets/images/bandung.png" alt="">
                    
                </a>			
                <i class="ion-ios-close-empty" id="sidebar-toggle-button-close"></i>
            </div>

            <div class="page-sidebar-inner">
                <div class="page-sidebar-menu">
                    <ul class="accordion-menu">
                        <!-- open active -->
                        <li class="" v-for="(item,index) in dataMenu" :key="index">
                            <!-- If not parent menu -->
                            <a v-if="!item.parent && (userdata.phone != 0 || item.title == 'Beranda') " @click="showHideMenu(index, 0)" :style="currentRouteName == item.url ? `background:#8f9075` : ''">
                                <router-link :to="{ name: item.url }" >
                                    <h6 class="text-white" style="font-size:14px">
                                        <img style="width:22px" :src="'https://kdo.e-planningbmd.id/assets/images/'+item.icon" class="sidebar-img-icon">
                                        &nbsp;{{item.title}}
                                    </h6>
                                </router-link>
                            </a>
                            <!-- If parent menu -->
                            <a v-if="item.parent && userdata.phone != 0" v-bind:id="`menu-${index}`" @click="showHideMenu(index, 1)" :style="currentRouteName == item.url ? `background:#8f9075` : ''">
                                <router-link :to="{ name: item.url }">
                                    <h6 class="text-white" style="font-size:14px;">
                                        <img style="width:22px" :src="'https://kdo.e-planningbmd.id/assets/images/'+item.icon" class="sidebar-img-icon">
                                        &nbsp;{{item.title}}
                                        <i class="text-white accordion-icon fa fa-angle-left"></i>
                                    </h6>
                                </router-link>
                            </a>
                            <ul v-if="item.parent" ref="menuSub" :class="'sub-menu'" v-bind:id="`child-${index}`" style="display:none">
                                <li v-for="(itemDetail,indexDetail) in item.sub_menu" :style="currentRouteName == itemDetail.url ? `background:#8f9075` : ''" :key="indexDetail">
                                    <router-link v-if="(userdata.parent == 999 && itemDetail.url == 'transaksi.dropping_keluar') || (userdata.parent > 999 && itemDetail.url == 'transaksi.dropping_masuk') || (itemDetail.url != 'transaksi.dropping_keluar' && itemDetail.menu_id == 4) || (itemDetail.url != 'transaksi.dropping_masuk' && itemDetail.menu_id == 3) || itemDetail.menu_id == 2 " :to="{ name: itemDetail.url }" >
                                        <h6  class="text-white" style="font-size:14px">{{itemDetail.title}}</h6>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                        <br><br>&nbsp;<br>
                    </ul>
                </div>
            </div>
            
        </div>

        <div class="page-content">
            <div class="page-header">
            <nav class="navbar navbar-expand-lg">
                <ul class="list-inline list-unstyled mg-r-20">
                    <!-- Mobile Toggle and Logo -->
                    <!-- <li class="list-inline-item align-text-top"><a class="hidden-md hidden-lg" href="" id="sidebar-toggle-button"><i class="ion-navicon tx-20"></i></a></li> -->
                    <!-- PC Toggle and Logo -->
                    <!-- <li class="list-inline-item align-text-top"><a class="text-dark hidden-xs hidden-sm" href="#" id="collapsed-sidebar-toggle-button"><i class="ion-navicon tx-20"></i></a></li> -->
                </ul>

                <!--================================-->
                <!-- Mega Menu Start -->
                <!--================================-->
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mr-auto">
                    </ul>
                </div>
                <div class="header-right pull-right">
                    <ul class="list-inline justify-content-end">
                        <li class="list-inline-item dropdown">
                        
                        <a  href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="text-white select-profile">{{ userdata.name }}</span>
                            <img src="https://sshsbu.e-planningbmd.id/assets/images/bandung.png" class="img-fluid wd-35 ht-35 rounded-circle" alt="">
                        </a>
                        
                        <div class="dropdown-menu dropdown-menu-right dropdown-profile shadow-2">
                            <div class="user-profile-area">
                                <div class="user-profile-heading">
                                    <div class="profile-thumbnail">
                                        <img src="https://sshsbu.e-planningbmd.id/assets/images/bandung.png" class="img-fluid wd-35 ht-35 rounded-circle" alt="">
                                    </div>
                                    <div class="profile-text">
                                    <h6>{{ userdata.nickname }}</h6>
                                    <span>{{ userdata.role }}</span>
                                    </div>
                                </div>
                                <span v-if="userdata.role != 'auditor'" >
                                <router-link to="/general/profile" class="dropdown-item"><i class="icon-user" aria-hidden="true"></i>Profil</router-link>
                                </span>
                                <router-link to="/signout" class="dropdown-item"><i class="icon-power" aria-hidden="true"></i>Logout</router-link>
                            </div>
                        </div>
                        </li>
                        <li class="hidden-md hidden-lg list-inline-item dropdown">
                        
                        <a  href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="text-white ion-navicon tx-20"></i>
                        </a>
                        
                        <div class="dropdown-menu dropdown-menu-right dropdown-profile shadow-2">
                            <div class="user-profile-area">
                                <div class="user-profile-heading">
                                    <div class="profile-text">
                                    <h6>MENU</h6>
                                    </div>
                                </div>
                                <div class="page-sidebar-inner">
                                    <div class="page-sidebar-menu">
                                        <ul class="accordion-menu">
                                            <!-- open active -->
                                            <li class="" v-for="(item,index) in dataMenu" :key="index">
                                                <!-- If not parent menu -->
                                                <a v-if="!item.parent" :style="currentRouteName == item.url ? `background:#8f9075` : ''">
                                                    <router-link :to="{ name: item.url }" >
                                                        <h6 class="text-black" style="font-size:14px">
                                                            <img style="width:22px" :src="'https://kdo.e-planningbmd.id/assets/images/'+item.icon" class="sidebar-img-icon">
                                                            &nbsp;{{item.title}}
                                                        </h6>
                                                    </router-link>
                                                </a>
                                                <!-- If parent menu -->
                                                <a v-if="item.parent" v-bind:id="`menu-${index}`"  :style="currentRouteName == item.url ? `background:#8f9075` : ''">
                                                    <router-link :to="{ name: item.url }">
                                                        <h6 class="text-black" style="font-size:14px;">
                                                            <img style="width:22px" :src="'https://kdo.e-planningbmd.id/assets/images/'+item.icon" class="sidebar-img-icon">
                                                            &nbsp;{{item.title}}
                                                            <i class="text-white accordion-icon fa fa-angle-left"></i>
                                                        </h6>
                                                    </router-link>
                                                </a>
                                                <ul v-if="item.parent" ref="submenu">
                                                    <li v-for="(itemDetail,indexDetail) in item.sub_menu" :style="currentRouteName == itemDetail.url ? `background:#8f9075` : ''" :key="indexDetail">
                                                        <router-link :to="{ name: itemDetail.url }" >
                                                            <h6  class="text-black" style="font-size:14px">- {{itemDetail.title}}</h6>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <br><br>&nbsp;<br>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </li>
                    </ul>
                </div>
                <!--/ Header Right End -->
            </nav>
            </div>
            
            <div class="page-inner">
            <div id="main-wrapper">

                <slot />

            </div>
            </div>
            <footer  class="page-footer">
                <div class="pd-t-4 pd-b-0 pd-x-20">
                    <div class="tx-10 tx-uppercase">
                        <p class="pd-y-10 mb-0">Copyright&copy; 2024</p>
                    </div>
                </div>
            </footer>	
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "SideBar",
        data() {
            return {
                dataMenu: [],
                isAktif: false,
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData');
            this.$store.dispatch('loadSchoolInfo');
            this.getData();
            this.currentRouteName;
        },
        computed: {
            ...mapState(['userdata']),
            currentRouteName() {
                return this.$route.name;
            }
        },
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "admin/menu/list_menu")
                    .then((response) => {
                        this.dataMenu = response.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            showHideMenu(index, parent){
                const subMenus = this.$refs.menuSub;
                if (subMenus) {
                    if (Array.isArray(subMenus)) {
                        subMenus.forEach(subMenu => {
                            subMenu.style.display = "none";
                        });
                    } else {
                        subMenus.style.display = "none";
                    }
                } else {
                    console.error("Elemen dengan ref 'submenu' tidak ditemukan.");
                }
                // document.querySelector("#child-1").style.display = "none";
                // document.querySelector("#child-3").style.display = "none";
                // document.querySelector("#child-4").style.display = "none";
                // document.querySelector("#child-5").style.display = "none";
                if(parent == 1){
                    document.querySelector("#child-"+index).style.display = "block";
                }
                if(this.isAktif){
                    this.isAktif = false
                }else{
                    this.isAktif = true;
                }
            }
        }
    }
</script>